import {Instance, types} from "mobx-state-tree"
import {Venue} from "./Venue"
import i18n from "../i18n"
import moment from "moment-timezone"

export enum ProductFormat {
  OFFER = "OFFER",
  OFFER_WITH_PRICE = "OFFER_WITH_PRICE",
  undefined = "undefined",
}

export const DEFAULT_PRODUCT_ACTIVE_PERIOD_SECONDS = 5 * 60

const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })

const ProductDescription = types.model("ProductDescription")
  .props({
    en: types.string,
    fi: types.string,
    es: types.string,
  })

const ProductTitle = types.model("ProductTitle")
  .props({
    en: types.string,
    fi: types.string,
    es: types.string,
  })

const ProductValidity = types.model("ProductValidity")
  .props({
    duration: types.maybeNull(types.integer),
    durationUnit: types.maybeNull(types.string)

  })

const ProductRules = types.model("ProductRules")
  .props({
    activation: types.maybeNull(types.integer),
    quantity: types.maybeNull(types.integer),
    type: types.maybeNull(types.string),
    isReward: types.maybeNull(types.boolean),
  })

const ProductPricing = types.model("ProductPricing")
  .props({
    amount: types.maybeNull(types.integer),
    vatPercentage: types.maybeNull(types.number),
    currency: types.maybeNull(types.string)
  })



function getKetValue<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

const VALID_LANGUAGE_CODES = ['en', 'fi']

const getValidLanguageCode = (languageCode: string) => {
  const validLanguageCode = VALID_LANGUAGE_CODES.find(code => code === languageCode)
  if (validLanguageCode) {
    return validLanguageCode
  }

  return 'en'
}

export const Product = types
  .model("Product")
  .props({
    id: types.identifier,
    restaurantId: types.reference(Venue),
    title: ProductTitle,
    description: ProductDescription,
    validity: ProductValidity,
    rules: ProductRules,
    pricing: ProductPricing,
    images: types.maybeNull(Images),
    visible: types.boolean
  })
  .views(self => ({
    localePrice(language: string | undefined = undefined) {
    },
  })).views(self => ({
    localeTitle(language: string | undefined = undefined) {
      const languageCode = getValidLanguageCode(language ? language : i18n.languages[0])
      // @ts-ignore
      return self.title[languageCode]
    },
    localeDescription(language: string | undefined = undefined) {
      const languageCode = getValidLanguageCode(language ? language : i18n.languages[0])
      // @ts-ignore
      return self.description[languageCode]
    },
  })).actions(self => ({
  }))

export type IProduct = Instance<typeof Product>
