import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import { useState } from "react"
import { Form as BootstrapForm, Container, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import DatePicker from "react-datepicker"
import { WithTranslation, withTranslation } from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import { MerchantAdminApi } from "../Api"
import { RewardModifyLocationParamsProps, RewardsLocation } from "../Locations"
import AlertMessage from "../components/AlertMessage"
import ImageUploadModal from "../components/ImageUploadModal"
import i18n from "../i18n"
import { IRewardStore, REWARD_TYPE } from "../models/RewardStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { colors } from "../theme/colors"
import { urlToFile } from "../utils"

const MODIFY_REWARD_ERROR = "modifyRewardError"
const CREATE_UPDATE_REWARD_ERROR = "createUpdateRewardError"
const NEW_REWARD_ID_PREFIX = "NEW__1"
export interface CreateUpdateRewardScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  rewardStore?: IRewardStore
  history?: any
}

const  CustomTimePicker = ({onClick, value, disabled}: { onClick?: () => void, value?: any, disabled?: boolean }) =>  {
    return  <Button disabled={disabled} className="mr-2" variant="secondary" onClick={onClick}><FontAwesomeIcon className="mr-2"
    icon={"clock"}/>{value}
</Button>

}


const CreateUpdateRewardScreen = (props: CreateUpdateRewardScreenProps) => {
  const [croppedRewardPhoto, setCroppedRewardPhoto] = useState('')
  const [showUploadRewardPhotoModal, setShowUploadRewardPhotoModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showInactivateRewardModal, setShowInactivateRewardModal] = useState(false);

  const params = useParams<RewardModifyLocationParamsProps>()

  console.log('params', params)

  const selectedRewardId = params.rewardId
  const selectedRewardType = params.rewardType
  // console.log('selectedRewardId', selectedRewardId)
  const selectedReward = props.rewardStore?.rewards?.find(reward => reward.id === selectedRewardId)
  const [uploadedImage, setUploadedImage] = useState(selectedReward?.images?.mainImage || "");



  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    // restaurantId: Yup.string().required(),
    title: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required'),
      es: Yup.string().required('Required'),
    }),
    description: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required'),
      es: Yup.string().required('Required'),
    }),
    timing_rules: Yup.object({
      validity: Yup.object({ 
        duration: Yup.number().required('Required'),
        durationUnit: Yup.string().required('Required')
      }),
    }),

    images: Yup.object({           
      mainImage: Yup.string(),
    }),
    assigned_venues: Yup.array().of(Yup.string()),
    ...(selectedRewardType === REWARD_TYPE.REWARD_BIRTHDAY ? {
      reward_trigger_time: Yup.date().required('Required')
    } : {}),
    active: Yup.boolean().required('Required')
  });

  const {t, userSessionStore, history, api} = props



  


  const initialNewRewardValues = {
    id: NEW_REWARD_ID_PREFIX,
    description: { 
      "en": "",
      "fi": "",
      "es": "",
    },
    timing_rules: {
      validity: { 
        duration: 30,
        durationUnit: "day" 
      }
    },
 
    title: {
      en: "",
      fi: "",
      es: "",
    },

    images: {           
      mainImage: ""
    },
    assigned_venues: userSessionStore?.isOrganizationContext() ? [] : [
      ...(userSessionStore?.selectedBranch?.id ? [userSessionStore?.selectedBranch?.id] : [])
    ],
    ...(selectedRewardType === REWARD_TYPE.REWARD_BIRTHDAY ? {
      reward_trigger_time: moment.utc().set({ hour: 11, minute: 30 }),
     } : {}),
    active: true
  }

  const initValues = selectedReward ? {
    id: selectedReward.id,
    description: { 
      "en": selectedReward.description.en,
      "fi": selectedReward.description.fi,
      "es": selectedReward.description.es,
    },
    timing_rules: {
      validity: { 
        duration: selectedReward.timing_rules?.validity?.duration || initialNewRewardValues.timing_rules.validity.duration,
        durationUnit: selectedReward.timing_rules?.validity?.durationUnit || initialNewRewardValues.timing_rules.validity.durationUnit 
      }
    },

    title: {
      en: selectedReward.title.en,
      fi: selectedReward.title.fi,
      es: selectedReward.title.es,
    },
  
    images: {           
      mainImage: selectedReward.images?.mainImage || ''
    },
    assigned_venues: selectedReward.assigned_venues?.map(venue => venue.id) || initialNewRewardValues.assigned_venues,
   ...(selectedRewardType === REWARD_TYPE.REWARD_BIRTHDAY ? {
    reward_trigger_time: moment.utc().set({ hour: 11, minute: 30 }),
   } : {}),
    active: Boolean(selectedReward.active),
  } : initialNewRewardValues

  const handleSaveAndPublish = async (reward: any, newReward: boolean) => {
    const response = await api!.postReward(reward, undefined, CREATE_UPDATE_REWARD_ERROR)
    console.log('postReward response', response)
    if (response) {
      if (newReward) {
        props.rewardStore?.addReward(response)
      } else {
        props.rewardStore?.updateReward(response)
      }
      history.push(RewardsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const getObjChangedValues = (values: any, initialValues: any) => {
    return Object
      .entries(values)
      .reduce((acc: any, [key, value]) => {
        const hasChanged = initialValues[key] !== value

        if (hasChanged) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  const IMAGE_UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    ERROR: "ERROR"
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/product/main', imageFile)
    if (imageURL) {
      setUploadedImage(imageURL)
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const MainImage = () => {
    if (uploadedImage === "") {
      return <Card className="croppedCover" style={{ border: `1px solid ${colors.stroke}`, backgroundColor: 'red'}}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedImage} alt="logo"/>
    }
  }

  const onBackToRewards = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(RewardsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }

  interface OptionType {
    value: string;
    label: string;
  }

  const VenuesSelectionDropdown = ({assignedVenues, setFieldValue}: {assignedVenues: string[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void}) => {
    const options = userSessionStore!.currentOrganization!.venues!.map(venue => ({
      value: venue.id,
      label: venue.name
    }));

    const onChange = (selectedOptions: OptionType[]) => {
      const selectedStatusIds = selectedOptions?.map(option => option.value) || [];

      setFieldValue('assigned_venues', selectedStatusIds)

    };

    const currentValue = options.filter(option => 
        assignedVenues.includes(option.value)
    );

    return (
        <div>
            <FormLabel className={`textInputLabel`}>{t(`rewardModifyScreen.availableTo`)}</FormLabel>
            <Select
        isMulti
        options={options}
        onChange={onChange}
        value={currentValue}
        className="react-select-container react-select-container-benefits-statuses"
        classNamePrefix="react-select"
        placeholder={t("rewardModifyScreen.allVenues")}

      />
        </div>
  
    );
  };

  const getFormTitle = () => {
    if (selectedRewardType === REWARD_TYPE.REWARD_JOIN) {}
    switch (selectedRewardType) {
      case REWARD_TYPE.REWARD_JOIN:
        return t("rewardsScreen.signUpReward")
      case REWARD_TYPE.REWARD_BIRTHDAY:
        return t("rewardsScreen.birthdayGift")
      default:
        return 'Unknown reward type'
    }
  }

  const getRewardTriggerText = () => {
    switch (selectedRewardType) {
      case REWARD_TYPE.REWARD_JOIN:
        return t("rewardModifyScreen.whenUserJoinsVenue")
      case REWARD_TYPE.REWARD_BIRTHDAY:
        return t("rewardModifyScreen.whenBirthdayTrigger")
      default:
        return 'Unknown reward type'
    }
  }



  
  return (
    <Container>
      <AlertMessage source={MODIFY_REWARD_ERROR} />

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("rewardsScreen.modals.backToRewards.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("rewardsScreen.modals.backToRewards.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("productModifyScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(RewardsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("rewardsScreen.modals.backToRewards.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

   
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          // const updatedFields = getObjChangedValues(values, initValues)

          const rewardTrigger = props.rewardStore?.rewardTriggers.find(trigger => trigger.type === selectedRewardType)

          const rewardTriggerId = rewardTrigger?.id || undefined

          if (!rewardTriggerId) {
            console.log('No rewardJoinTriggerId')
            return
          }

          const rewardData = {
            ...values,
            images: {           
              mainImage: uploadedImage || null
            },
            reward_trigger_id: rewardTriggerId,
    
          }
          const isNewReward = values.id === NEW_REWARD_ID_PREFIX

          console.log('rewardData', rewardData)
          handleSaveAndPublish(rewardData, isNewReward)
        }}
      >
        {({ errors, touched, values, dirty, handleChange, resetForm, setFieldValue }) => {
          console.log("FORM", errors)
          console.log('values', values)
          return (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  // setUploadedImage(selectedVenue?.images?.logoImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showInactivateRewardModal} centered onHide={() => {
                setShowInactivateRewardModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("rewardModifyScreen.modals.hideReward.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("rewardModifyScreen.modals.hideReward.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowInactivateRewardModal(false)
                  setFieldValue('active', true)
                }}>
                  {t("manageVenuesScreen.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  setShowInactivateRewardModal(false)
                  setFieldValue('active', false)
                }}>
                  {t("manageVenuesScreen.hide")}
                </Button>
              </Modal.Footer>
            </Modal>


            <Row>
              <Col md={4}>
                <Button 
                  variant="secondary" 
                  className="font-weight-bold" 
                  onClick={() => onBackToRewards(dirty)}
                  >
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                    {t("stampCardModifyScreen.backToRewards")}
                </Button>
              </Col>
              <Col md={8}>
                <div className="float-right">
                  <Button variant="outline-primary font-weight-bold" className="mr-3" onClick={() => setShowRevertModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]}/>
                      {t("manageVenuesScreen.revert")}
                  </Button>
                  <Button variant="outline-success greenInput" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </div>
                
              </Col>
            
            </Row>

            <Container>
              <Row className="justify-content-md-center mt-5 mb-4">
                <div style={{
                    textAlign: 'center'
                }}>
                <h2 className="p-0 m-0 mb-4 text-primary font-weight-bold">{getFormTitle()}</h2>
                {t(`rewardModifyScreen.formDescription`).split('\n').map((text: any) => 
                    <p className="p-0 m-0 font-size-medium">{text}</p>
                    )}
                </div>
              </Row>
            </Container>


            

            {!userSessionStore!.currentOrganization!.isSingleVenue() &&
                <>
                <Container className="p-2" style={{
                border: `1px solid ${colors.stroke}`,
                borderBottom: 'none',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}>
                <Row>
                    <div className="m-0 font-size-medium" style={{
                        paddingLeft: 30
                    }}>{t('rewardModifyScreen.venues')}</div>
                </Row>
            </Container>
                    <Container className="formContainer mb-4" style={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                    }}>
                        <Row>
                            <Col md={6}>
                                <VenuesSelectionDropdown assignedVenues={values.assigned_venues} setFieldValue={setFieldValue}/>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="p-2" style={{
                        border: `1px solid ${colors.stroke}`,
                        borderBottom: 'none',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                    }}>
                        <Row>
                            <div className="m-0 font-size-medium" style={{
                                paddingLeft: 30
                            }}>{t('rewardModifyScreen.reward')}</div>
                        </Row>
                    </Container>
                </>
            }
          

            <Container className="formContainer" style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
            }}>
              <Row>
                <Col md={6}>
                  <Field 
                    name="title.en"
                    render={() => (
                      <FormGroup controlId="title.en">
                        <FormLabel 
                          className={`textInputLabel ${(errors.title?.en && touched.title?.en) && (errors.title?.fi && touched.title?.fi) ? 'text-danger' : ''}`}>
                            {t(`rewardModifyScreen.titleField`)}
                        </FormLabel>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-2">EN</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            disabled={Boolean(selectedReward?.time_frame_reached)}
                            placeholder={t(`rewardModifyScreen.titlePlaceholderEN`)} 
                            type={'text'} 
                            isInvalid={errors.title?.en && touched.title?.en ? true : false} 
                            value={values.title.en} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.en && touched.title?.en? (
                          <small className="text-danger">{errors.title?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="title.fi"
                    render={() => (
                      <FormGroup controlId="title.fi">
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-3">FI</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            placeholder={t(`rewardModifyScreen.titlePlaceholderFI`)} 
                            disabled={Boolean(selectedReward?.time_frame_reached)}
                            type={'text'} 
                            isInvalid={errors.title?.fi && touched.title?.fi ? true : false} 
                            value={values.title?.fi} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.fi && touched.title?.fi ? (
                          <small className="text-danger">{errors.title?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.en"
                    render={() => (
                      <FormGroup controlId="description.en">
                        <FormLabel className={`textInputLabel ${errors.description?.en && touched.description?.en ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInEN`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.en && touched.description?.en ? true : false} 
                          placeholder={t(`productModifyScreen.descriptionInEN`)}
                          value={values.description?.en} 
                          onChange={handleChange}/>
                        {errors.description?.en && touched.description?.en ? (
                          <small className="text-danger">{errors.description?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.fi"
                    render={() => (
                      <FormGroup controlId="description.fi">
                        <FormLabel className={`textInputLabel ${errors.description?.fi && touched.description?.fi ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInFI`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.fi && touched.description?.fi ? true : false} 
                          placeholder={t(`cardModifyScreen.descriptionPlaceholderFI`)}
                          value={values.description?.fi} 
                          onChange={handleChange}/>
                        {errors.description?.fi && touched.description?.fi ? (
                          <small className="text-danger">{errors.description?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <ImageUploadModal 
                show={showUploadRewardPhotoModal}
                imageURL={values.images.mainImage}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedRewardPhoto(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadRewardPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadRewardPhotoModal(false)
                  handleUploadImage(croppedRewardPhoto)
                }}/>

              <Row>
                <Col md={6}>
                  <Field 
                    name="images.mainImage"
                    render={() => (
                      <FormGroup controlId="images.mainImage" >
                        <h6 className={`mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`stampCardModifyScreen.rewardPhoto`)}</h6>
                        <div className="mb-2">
                          <small>{t('productModifyScreen.productPhotoDescription')}</small>
                        </div>

                        <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadPhoto`)}
                            <input 
                              type="file" 
                              id="images.mainImage"
                              hidden
                              name="file"
                              onChange={(event) => {
                                  if (event.currentTarget && event.currentTarget.files) {
                                    setFieldValue("images.mainImage", URL.createObjectURL(event.currentTarget.files[0]));
                                    setShowUploadRewardPhotoModal(true)
                                  }
                              }} />
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row style={{height: 200}}>
                <Col md={4}>
                  <MainImage/>
                </Col>
              </Row>


                <Row className="align-items-center mt-3">

                <Col md={3}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                          <div className="mb-2">
                            <div style={{
                                 fontSize: 14
                            }}>{t('rewardModifyScreen.rewardExpirationInDays')}</div>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                      <Field 
                    name="timing_rules.validity.duration"
                    render={() => (
                      <FormGroup controlId="timing_rules.validity.duration">
                        <FormControl 
                        disabled={Boolean(selectedReward?.time_frame_reached)} 
                        
                        className="textInput text-center primaryInput" as='select' isInvalid={errors.timing_rules?.validity?.duration && touched.timing_rules?.validity?.duration ? true : false} value={values?.timing_rules?.validity?.duration || 0} 
                        onChange={
                          (e: any) => {
                            setFieldValue('timing_rules.validity.duration', Number(e.target.value))
                          }
                        }>
                          <option value={7}>7</option>
                          <option value={14}>14</option>
                          <option value={30}>30</option>
                          <option value={60}>60</option>
                          <option value={90}>90</option>
                          <option value={180}>180</option>
                        </FormControl>
                        {errors.timing_rules?.validity?.duration && touched.timing_rules?.validity?.duration ? (
                          <small className="text-danger">{errors.timing_rules?.validity?.duration}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                      </Col>
                    </Row>
                  </Container>
                </Col>

              </Row>

              
        

            </Container>

            <Container className="p-2 mt-4" style={{
                border: `1px solid ${colors.stroke}`,
                borderBottom: 'none',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}>
                <Row>
                    <div className="m-0 font-size-medium" style={{
                        paddingLeft: 30
                    }}>{t('rewardModifyScreen.triggerAndConditions')}</div>
                </Row>
            </Container>

            <Container className="formContainer" style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
            }}>
                <Row>
                    <Col md={12}>
                    {getRewardTriggerText()}

                    {selectedRewardType === REWARD_TYPE.REWARD_BIRTHDAY &&
                    <div className="mt-3">
  
                      <DatePicker
                        selected={new Date((values.reward_trigger_time))}
                        onChange={(date: Date) => setFieldValue('reward_trigger_time', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        locale={i18n.languages[0]}
                        timeIntervals={30}
                        disabled={true}
                        timeCaption={t("time")}
                        dateFormat="p"
                        customInput={<CustomTimePicker disabled/>}
                      />
                    </div>

                    }
                 
                    </Col>
                </Row>
                
            </Container>

            <FormGroup controlId="active">
            <BootstrapForm.Check 
                type="switch"
                id="active"
                className="mt-5"
                checked={!values.active}
                onChange={() => {
                  if (!values.active) {
                    setFieldValue('active', true)
                  } else {
                    setShowInactivateRewardModal(true)
                  }
                }}
                label={t("rewardModifyScreen.hideRewardFromUsers")}
              />
            </FormGroup>

            <Button variant="outline-success greenInput mt-4" type="submit" className="font-weight-bold">
                <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                {t("manageVenuesScreen.saveAndPublish")}
            </Button>
          </Form>
        )}}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "rewardStore")(observer(CreateUpdateRewardScreen)))
