import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import TruncateText from "../../../components/TruncateText/TruncateText";
import { useStores } from "../../../hooks/UseStores";
import { MemberLocation, RecentActivitiesLocation } from "../../../Locations";
import { IMemberActivity } from "../../../models/MemberStore";
import { colors } from "../../../theme/colors";
import { timeAgo } from "../../../utils/dateUtils";
import blankProfileImage from "../NewMembersList/blank-profile.png";
import { MEMBER_ACTIVITY_TYPE } from "./types";
import { getActivityText, getDisplayLength } from "./utils";

const cardStyle: CSSProperties = {
  backgroundColor: "#1e1e1e",
  color: "#ffffff",
  borderRadius: "10px",
  height: "100%"
};

const listItemStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  cursor: "pointer",
  minHeight: "48px",
  height: "48px",
  maxHeight: "48px",
  padding: "4px 0"
};

const imageStyle: CSSProperties = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  objectFit: "cover",
  marginRight: "10px",
};

const infoStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};


const RecentActivitiesList = observer(() => {
  const { t, i18n } = useTranslation();
  const { userSessionStore, memberStore } = useStores();
 
  const history = useHistory();

  const activities: IMemberActivity[] = memberStore.recentActivities


  const handleMemberClick = (memberId: string) => {
    history.push(MemberLocation.toUrl({
      ...userSessionStore!.selectedBranch!.urlFields(),
      memberId
    }));
  };

  const handleSeeMoreActivities = () => {
    history.push(RecentActivitiesLocation.toUrl({
      ...userSessionStore!.selectedBranch!.urlFields(),
      idType: 'venue',
      id: userSessionStore!.selectedBranch!.id
    }));
  };

  const displayLength = getDisplayLength({
    currentLength: activities.length,
    otherLength: memberStore.newMembers.length
  });

  if (!Boolean(activities.length)) {
    return null
  }

  return (
    <Card style={cardStyle}>
      <Card.Header as="h5" style={{
        backgroundColor: "#252525",
        borderLeft: `1px solid ${colors.stroke}`,
        borderTop: `1px solid ${colors.stroke}`,
        borderRight: `1px solid ${colors.stroke}`,
      }}>
        {t("homeScreen.recentActivities")}
      </Card.Header>
      <Card.Body>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {activities.slice(0, displayLength).map((activity, index) => {

          if (!activity) {
          return null
          }

        return    <li 
              key={index} 
              style={listItemStyle}
              onClick={() => {
                if (activity.member?.id) {
                  handleMemberClick(activity.member?.id)
                } else {
                  console.warn('No member id found for activity', activity)
                }
              }}
            >
              <img
                src={activity.member?.profileImages?.small || blankProfileImage}
                alt={activity.member?.name}
                style={imageStyle}
              />
              <div style={infoStyle}>
                <div>
                <TruncateText numberOfLines={1} text={`${activity.member?.name} ${getActivityText(activity, t)}`}/>
                </div>
                <small style={{ color: colors.secondaryText, fontSize: 12 }}>
                  {timeAgo(activity.actionDate)}
                </small>
              </div>
            </li>
})}
        </ul>

        {memberStore.recentActivities.length > 9 && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              variant="text"
              onClick={handleSeeMoreActivities}
            >
              {t("homeScreen.seeMoreActivities")}
            </Button>
          </div>
        )}
        
      </Card.Body>
    </Card>
  );
});

export default RecentActivitiesList;