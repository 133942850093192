import { IBucketMember } from "../Member";
import { IMemberActivity, MEMBER_ACTIVITY_TYPE } from "../MemberStore";

export const transformRecentActivitiesPayload = (rawActivities: any[]) => {
  if (!rawActivities) return { activities: [], memberIds: new Set<string>() };

  const memberIds = new Set<string>();
  
  const activities = rawActivities.map(activity => {
    if (activity.userId) {
      memberIds.add(activity.userId);
    }

    const baseActivity = {
      id: activity.id,
      type: activity.actionType,
      actionDate: activity.actionDate,
      member: activity.userId,
      venue: activity.restaurantId,
      benefit: null,
      event: null,
      subscription: null,
      product: null,
      stampCard: null
    };
  
    switch (activity.actionType) {
      case MEMBER_ACTIVITY_TYPE.BENEFIT_ACTIVATION:
        baseActivity.benefit = activity.id;
        break;
      case MEMBER_ACTIVITY_TYPE.EVENT_PURCHASE:
        baseActivity.event = activity.id;
        break;
      case MEMBER_ACTIVITY_TYPE.VENUE_STATUS_PURCHASE:
        baseActivity.subscription = activity.id;
        break;
      case MEMBER_ACTIVITY_TYPE.VENUE_PRODUCT_PURCHASE:
      case MEMBER_ACTIVITY_TYPE.PRODUCT_ACTIVATION:
        baseActivity.product = activity.id;
        break;
      case MEMBER_ACTIVITY_TYPE.STAMP:
        baseActivity.stampCard = activity.id;
        break;
    }
  
    return baseActivity;
  });

  return { activities, memberIds };
}

export const filterActivitiesWithValidMember = ({ 
  activities, 
  membersBucket 
}: { 
  activities: IMemberActivity[], 
  membersBucket: Map<string, IBucketMember> 
}) => {
  return activities.filter(activity => {
    if (typeof activity.member === 'string') {
      return membersBucket.has(activity.member);
    }
    return activity.member && membersBucket.has(activity.member.id);
  });
};