import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import log from "loglevelnext";
import { inject, observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { Moment } from "moment";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from 'react';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { MerchantAdminApi } from "../../Api";
import { MemberLocation, MembersLocation } from "../../Locations";
import AlertMessage from "../../components/AlertMessage";
import { ModalBox } from "../../components/ModalBox";
import TruncateText from "../../components/TruncateText/TruncateText";
import i18n from "../../i18n";
import {
  IMember,
  IMemberVenueMembership,
  IMemberVenueStatus,
  MEMBER_VENUE_STATUS_ORIGIN_PURCHASE
} from "../../models/Member";
import { IMemberStore, IVenueStatus } from "../../models/MemberStore";
import { IRootStore } from "../../models/RootStore";
import { IUserSessionStore } from "../../models/UserSessionStore";
import { IVenue } from "../../models/Venue";
import { colors } from "../../theme/colors";
import { centsToFullCurrencyUnits } from "../../utils";
import './Member.css';
import { Spinner } from "react-bootstrap";
import { useMemberWithTimeline } from "./useMemberWithTimeline";
import { getOrganizationTimezone } from "../../utils/dateUtils";

const MEMBER_SCREEN_ERROR = "memberScreen"


export interface MemberScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  api?: MerchantAdminApi
  rootStore?: IRootStore
}



const MemberScreen = inject('userSessionStore', 'memberStore', 'api', 'rootStore')(observer((props: MemberScreenProps) => {
  const {
    userSessionStore,
    memberStore,
    api,
    rootStore,
    t,
    history,
    location
  } = props;

  const locationParams = MemberLocation.parseLocationParams();
  const [forceResolveMemberFlag, setForceResolveMemberFlag] = useState(false);
  const [showRemovalSuccessModal, setShowRemovalSuccessModal] = useState(false);
  const [removedMembershipName, setRemovedMembershipName] = useState('');
  const [deletedMembershipIds, setDeletedMembershipIds] = useState<string[]>([]);

  const handleMembershipRemovalComplete = () => {
    setShowRemovalSuccessModal(false);
    
    const remainingMemberships = member?.venueMemberships?.filter(
      membership => !deletedMembershipIds.includes(membership.id.id)
    ) || [];

    if (remainingMemberships.length === 0) {
      history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()));
    }
  };

  const removeStatusConfirmModalRef = useRef<{ current: { show: (action: () => void, metadata?: any) => void } } | null>(null);
  const changeStatusNumberModalRef = useRef<{ current: { show: (action: () => void, metadata?: any) => void } } | null>(null);
  const removeMembershipModalRef = useRef<{ current: { show: (action: () => void, metadata?: any) => void } } | null>(null);
  const memberData = useMemberWithTimeline(memberStore, locationParams.memberId);
 

  if (!memberData || !memberStore || !locationParams.memberId) {
    return (
      <div className="container p-0">
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Card.Body className="text-center p-5">
                <div style={{ color: colors.secondaryText, fontSize: 16 }}>
                  {t("memberProfileScreen.noMemberFound")}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  const { loading, member, timeline: memberTimeline } = memberData;

  const formatTimezone: string = getOrganizationTimezone(props.userSessionStore)


  if (loading || !member) {
    return   <div className="text-center mt-5">
    <Spinner animation="border" role="status" variant="primary">
    </Spinner>
  </div>
  }

  const _renderMembershipsOrg = (member: IMember) => {
    const filteredMemberships = member?.venueMemberships?.filter(
      membership => !deletedMembershipIds.includes(membership.id.id)
    ) || [];
   
    return (
      <>
        <div className="mt-3 mb-1" style={{fontSize: 14, fontWeight: 'bold'}}>{t("memberProfileScreen.membershipsTitle")}</div>
          <div style={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
          }}>
            {filteredMemberships.map((memberShip: IMemberVenueMembership) => {
      
              return (
                <div className="pl-1 pr-1" style={{display: 'inline-block', textAlign: 'center', minWidth: '33.33%', maxWidth: '33.33%', minHeight: 135, maxHeight: 135, marginTop: 10, position: 'relative'}}>
                  <div style={{position: 'absolute', right: 16, top: -8, cursor: 'pointer', zIndex: 1}}>
                    <FontAwesomeIcon 
                      icon={["fal", "minus-circle"]} 
                      style={{
                        fontSize: 16
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        removeMembershipModalRef.current?.current?.show(() => {
                          const remove = async () => {
                            const result = await memberStore?.removeMemberFromVenue(member.id, memberShip.id.id, MEMBER_SCREEN_ERROR);

                            if (result?.success) {
                              const venueName = memberShip.id.name;
                              setRemovedMembershipName(venueName ?? '');
                              setDeletedMembershipIds(prev => [...prev, memberShip.id.id]);
                              setShowRemovalSuccessModal(true);
                            }
                          }
                          rootStore!.dimWhile(remove)
                        }, {venueName: memberShip.id.name})
                      }}
                    />
                  </div>
                  <img 
                    alt="membershipItem"
                    src={memberShip.id.images?.logoImage || memberShip.id.images?.mainImage || ''} 
                    style={{width: 80, height: 80, objectFit: 'cover', borderRadius: '50%'}}/>
                    <div className="mt-1" style={{fontSize: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            
                      <TruncateText numberOfLines={2} text={`${memberShip.id.name}`}/>
                      
                    </div>
                    <div style={{fontSize: 10, color: '#7E7E7E'}}>
                     {moment.utc(memberShip.memberSince).tz(formatTimezone).format("DD.MM.YYYY")}
                    </div>
                </div>
              )
            })}
          </div>
     
      </>
    )
  }

  

    const currentLanguage = i18n.languages[0]
    //let addStatusConfirmModalRef: any

    const StatisticsSummary = () => {
      return (
        <div className="mt-3">
          <div className="mr-5" style={{display: 'inline-block', textAlign: 'center'}}>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>{t("memberProfileScreen.totalPurchases")}</div>
            {typeof member.totalPurchases  === 'number'  &&
              <div style={{fontSize: 20, fontWeight: 'bold'}}>
                {centsToFullCurrencyUnits(member.totalPurchases)} €
              </div>
            }
            
          </div>

          <div style={{display: 'inline-block', textAlign: 'center'}}>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>{t("memberProfileScreen.benefitsUsed")}</div>
            <div style={{fontSize: 20, fontWeight: 'bold'}}>
              {member.totalBenefitsActivations}
            </div>
          </div>
        </div>
      )
    }

    const CardItem  = ({card}: any) => {
      const IMAGE_WIDTH = 85
      const IMAGE_HEIGHT = 51

      const CardNumber = () => {

        if (!card.externalIdentifier || !card.showExternalIdentifier) {
          return null
        }

        return <div style={{
          fontWeight: 'normal',
          fontSize: 11,
          position: 'absolute',
          top: 0,
          right: 0,
          background: colors.boxFill,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: 5,
          paddingLeft: 4,
          paddingRight: 4,
          opacity: 0.9
        }}>
          #{card.externalIdentifier}
        </div>
      }


      return (
        <div className="pl-1 pr-1" style={{display: 'inline-block', textAlign: 'left', width: '50%', maxWidth: '50%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'inline-block', width: IMAGE_WIDTH,  height: IMAGE_HEIGHT, position: 'relative'}}>
              <img 
                alt="membershipCard"
                src={card.image} 
                style={{width: IMAGE_WIDTH, height: IMAGE_HEIGHT, objectFit: 'cover', borderRadius: '5px'}}/>
                <CardNumber/>
            </div>
            
            <div  style={{
              fontSize: 12, 
              display: 'inline-block', 
              height: IMAGE_HEIGHT,
              width: `calc(100% - ${IMAGE_WIDTH}px)`,
            }}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: IMAGE_HEIGHT, paddingLeft: 10}}>
                <div style={{
                  fontWeight: 'bold',
                  fontSize: 12,
               
                }}>
                 
                    <TruncateText numberOfLines={1} text={`${card.title}`}/>
                </div>
                <div style={{
                  fontSize: 10,
                }}>
                  <TruncateText numberOfLines={1} text={`${card.venueName}`}/>
                </div>
                <div style={{fontSize: 9, color: '#7E7E7E'}}>
                  {card.date}
                </div>
              </div>
            </div>
          </div>
          
          <div style={{textAlign: 'left', height: 16}}>
            {card.onRemove &&
              <a href="#remove" style={{
                fontSize: 12,
                color: colors.secondaryText
              }} onClick={card.onRemove}>
                <FontAwesomeIcon className="mr-1"
                              icon={["fal", "trash-alt"]}/> {t("memberProfileScreen.removeStatusButton")}
              </a>
            }
          
          </div>

          <div style={{textAlign: 'left', marginTop: 5, marginBottom: 5}}>

          
          {card.onExternalIdentifierChange &&
              <a href="#changeNumber"  style={{
                fontSize: 12,
                color: colors.secondaryText,
                visibility: card.showExternalIdentifier ? 'visible' : 'hidden'
              }} onClick={card.onExternalIdentifierChange}>
                <FontAwesomeIcon className="mr-1"
                              icon={["fal", "edit"]}/> {t("memberProfileScreen.changeNumber")}
              </a>
            }
          </div>
        </div>
      )
    }

    const CardsAndSubscriptions = ({member}: {member: IMember}) => {
  
      const purchasedCardsAndSubscriptions = member.venueStatuses.filter(status => status.id.type === "PURCHASE").map(memberVenueStatus => {
        const date = `${moment.utc(memberVenueStatus.validFrom).tz(formatTimezone).format("DD.MM.YYYY")} - ${memberVenueStatus.validThrough ? moment.utc(memberVenueStatus.validThrough).tz(formatTimezone).format("DD.MM.YYYY") : t("memberProfileScreen.statusNoEndTime")}`
        return {
          title: memberVenueStatus.id.localeTitle,
          venueName: memberVenueStatus.id.restaurantId.name,
          date: date,
          externalIdentifier: memberVenueStatus.externalIdentifier,
          image: memberVenueStatus.id.images?.mainImage || memberVenueStatus.id.restaurantId.images?.mainImage,
        }
      })

      const cardsGivenByRestaurant: any[] = [

      ]

      memberStore!.venuesWithStatuses.forEach((venue: IVenue) => {
        _.orderBy(member!.activeStatuses(venue), ["validThrough", "id.localeTitle"], ["asc", "asc"]).forEach((memberVenueStatus: IMemberVenueStatus) => {
          const date = `${moment.utc(memberVenueStatus.validFrom).tz(formatTimezone).format("DD.MM.YYYY")} - ${memberVenueStatus.validThrough ? moment.utc(memberVenueStatus.validThrough).tz(formatTimezone).format("DD.MM.YYYY") : t("memberProfileScreen.statusNoEndTime")}`
          if (memberVenueStatus.origin.type === MEMBER_VENUE_STATUS_ORIGIN_PURCHASE) {
            return undefined
          }

          
          cardsGivenByRestaurant.push({
            title: memberVenueStatus.id.localeTitle,
            venueName: memberVenueStatus.id.restaurantId.name,
            date: date,
            showExternalIdentifier: memberVenueStatus?.id?.flags?.showExternalIdentifier,
            externalIdentifier: memberVenueStatus.externalIdentifier,
            image: memberVenueStatus.id.images?.mainImage || memberVenueStatus.id.restaurantId.images?.mainImage,
            onRemove: (e: any) => {
              e.preventDefault()
              
              removeStatusConfirmModalRef.current?.current?.show(() => {
                const remove = async () => {
                  await api?.removeMemberVenueStatus(member.id, memberVenueStatus.id.id, undefined, MEMBER_SCREEN_ERROR)
                  member.removeStatus(memberVenueStatus)
                }
                rootStore!.dimWhile(remove)
              }, {statusTitle: memberVenueStatus.id.localeTitle})
            },
            onExternalIdentifierChange: (e: any) => {
              e.preventDefault()
              
              changeStatusNumberModalRef.current?.current?.show(() => {

              }, {externalIdentifier: memberVenueStatus.externalIdentifier, userVenueStatusId: memberVenueStatus.userVenueStatusId})
            }
          })
        })
      })

      const isAddableCards = () => {
        let isCardsToAdd = false

        memberStore!.venuesWithStatuses.forEach((venue: IVenue) => {
          if (memberStore!.passiveVenueStatuses(venue, member).length > 0) {
            isCardsToAdd = true
          }
        })

        return isCardsToAdd
      }

      const GiveCardsButton = () => {
        if (!isAddableCards()) {
          return null
        }

        const renderStatusItem = (status: IVenueStatus) => {
          return (
              <Dropdown.Item onClick={(e: any) => {
                e.preventDefault();
                const add = async () => {
                  const addedStatus = member.addStatus(status);
                  // @ts-ignore
                  const addResult = await api?.addMemberVenueStatus(member.id, getSnapshot(addedStatus), undefined, MEMBER_SCREEN_ERROR);
        
                  if (addResult) {
                  } else {
                    member.removeStatus(addedStatus);
                  }
                };
                rootStore!.dimWhile(add);
              }} key={status.id}>{status.localeTitle}</Dropdown.Item>
          );
      };

        return (
          <Dropdown
              className="mt-3" 
              onClick={(e: any) => e.stopPropagation()}
          >
              <Dropdown.Toggle variant="primary" id="dropdownMenuButton">
                <FontAwesomeIcon className="mr-2"
                                  icon={["fal", "plus-circle"]}/>{t("memberProfileScreen.addNewStatusButton")}
              </Dropdown.Toggle>

              {memberStore!.venuesWithStatuses.length === 1 ? (
                  <Dropdown.Menu>
                      {_.orderBy(memberStore!.passiveVenueStatuses(memberStore!.venuesWithStatuses[0], member), "localeTitle", "asc").map((status: IVenueStatus) => renderStatusItem(status))}
                  </Dropdown.Menu>
              ) : (
                  <Dropdown.Menu>
                      {memberStore!.venuesWithStatuses.map((venue: IVenue) => (
                          <Dropdown as="li" className="dropdown-submenu" drop="right">
                              <Dropdown.Toggle as="a" className="dropdown-item">
                                  {venue.name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu >
                              {_.orderBy(memberStore!.passiveVenueStatuses(venue, member), "localeTitle", "asc").map((status: IVenueStatus) => renderStatusItem(status))}
                              </Dropdown.Menu>
                          </Dropdown>
                      ))}
                  </Dropdown.Menu>
              )}
          </Dropdown>
        )
      }

      return (
        <div>
          {purchasedCardsAndSubscriptions.length === 0 && cardsGivenByRestaurant.length === 0 &&
            <div style={{color: colors.secondaryText}}>
              {t("memberProfileScreen.noVenueStatusesText")}
            </div>
          }

          {purchasedCardsAndSubscriptions.length > 0 &&
            <div className="mb-3" style={{fontSize: 14, color: colors.secondaryText}}>{t("memberProfileScreen.cardsAndSubscriptionsDescription")}</div>
          }
          
          {purchasedCardsAndSubscriptions.map(card => {
            return (
              <CardItem card={card}/>
            )
          })}

          {cardsGivenByRestaurant.length> 0 &&
            <div className="mb-3" style={{fontSize: 14, color: colors.secondaryText}}>{t("memberProfileScreen.givenCardsDescription")}</div>
          }

        {cardsGivenByRestaurant.map(card => {
            return (
              <CardItem card={card}/>
            )
          })}

          <GiveCardsButton/>
        </div>
      )
    }


    const RecentActivities = () => {
      interface Item {
        actionDate: string;
        type: string;
        groupName?: string;
        collectedStampsCount?: number;
        [key: string]: any;
      }
      
      interface GroupedResults {
        [groupName: string]: Item[];
      }
      
      function mergeArrays(obj: Record<string, Item[]> | undefined): Item[] {
        if (!obj) {
          return [];
        }
      
        return Object.entries(obj).flatMap(([key, value]) => {
          if (key === 'venueStampGathering') {
            const groupedItems = value.reduce((acc: Item[], item: Item) => {
              const fifteenSeconds = 15000; 
              const itemMoment = moment(item.actionDate);
      
              const existingItem = acc.find(i => {
                const existingItemMoment = moment(i.actionDate);
                const isSameId = i.id.id === item.id.id;
                const isWithinFifteenSeconds = Math.abs(itemMoment.diff(existingItemMoment)) <= fifteenSeconds;
                return isSameId && isWithinFifteenSeconds;
              });
      
              if (existingItem) {
                existingItem.collectedStampsCount = (existingItem.collectedStampsCount || 1) + 1;
              } else {
                acc.push({ ...item, type: key, collectedStampsCount: 1 });
              }
      
              return acc;
            }, []);
      
            return groupedItems;
          }
      
          return Array.isArray(value) ? value.map(item => ({ ...item, type: key })) : [];
        });
      }
      
      function sortItemsByDate(arr: Item[]): Item[] {
        return arr.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime());
      }
      
      function getGroupName(actionDate: Moment): string {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const currentYear = moment().year();
      
        if (actionDate.isSame(today, 'day')) {
          return t("memberProfileScreen.activities.today");
        } else if (actionDate.isSame(yesterday, 'day')) {
          return t("memberProfileScreen.activities.yesterday");
        } else if (actionDate.year() === currentYear) {
          return actionDate.format('MMMM');  // Group by month
        } else {
          return actionDate.format('YYYY');  // Group by year
        }
      }
      function groupItemsByDate(arr: Item[]): GroupedResults {
        return arr.reduce((groups: GroupedResults, item: Item) => {
          const actionDate = moment(item.actionDate);
          const groupName = getGroupName(actionDate);
      
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(item);
      
          item.groupName = groupName;
      
          return groups;
        }, {});
      }
      
      function mergeByDateGroupsAndSortArrays(obj: Record<string, Item[]> | undefined): GroupedResults {
        if (!obj) {
          return {}
        }
        const mergedArray = mergeArrays(obj);
        const sortedArray = sortItemsByDate(mergedArray);
        const groupedResults = groupItemsByDate(sortedArray);
      
        return groupedResults;
      }
      


      // @ts-ignore
      const timeline = mergeByDateGroupsAndSortArrays(memberTimeline)
      const timeLineItemsCount = mergeArrays(timeline).length
      const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(10)


      const GroupedItems = () => {
      
        const groupedItems = timeline;

        const title = (item: any) => {
          if (item.type === "venueStatuses") {
            const purchaseTypeText = item.id.isSubscription ? t("memberProfileScreen.activities.purchasedASubscription") 
            : t("memberProfileScreen.activities.purchasedACard")
            return `${purchaseTypeText} · ${item.id.localeTitle}`
          }

          if (item.type === "venueMemberships") {
            return `${t("memberProfileScreen.activities.joinedAsAMember")} · ${item.id.name}`
          }

          if (item.type === "venueStampGathering") {
            const isMultipleStamps = item.collectedStampsCount && item.collectedStampsCount > 1
            const stampsText = isMultipleStamps ? t("memberProfileScreen.activities.collectedStamps", { stampsCount: item.collectedStampsCount }) : t("memberProfileScreen.activities.collectedAStamp")
            return `${stampsText} · ${item.id?.localeName()} · ${item.restaurantId?.name}`
          }

          if (item.type === "venueBenefitsActivations") {
            return `${t("memberProfileScreen.activities.activatedABenefit")} · ${item.id.localeTitle(currentLanguage)}`
          }

          if (item.type === 'venueEventPurchase') {
            return `${t("memberProfileScreen.activities.purchasedATicket")} · ${item.id.title}`
          }

          if (item.type === 'venueEventActivation') {
            return `${t("memberProfileScreen.activities.activatedATicket")} · ${item.id.title}`
          }

          if (item.type === 'venueProductActivation') {
            return `${item.id?.rules?.isReward ? t("memberProfileScreen.activities.usedAStampCardReward") : t("memberProfileScreen.activities.activatedAProduct")} · ${item.id.title[currentLanguage]}`
          }

          if (item.type === 'venueProductPurchase') {
            return `${t("memberProfileScreen.activities.purchasedAProduct")} · ${item.id.title[currentLanguage]}`
          }

          return ''
        }


        function getLimitedItems(groupedItems: GroupedResults, limit: number) {
          // @ts-ignore
          let allItems = [];
      
          for (let group in groupedItems) {
            // @ts-ignore
              allItems = [...allItems, ...groupedItems[group].map(item => ({ ...item, groupName: group }))];
          }
      // @ts-ignore
          allItems.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime());
      // @ts-ignore
          let limitedItems = allItems.slice(0, limit);
          let limitedGroupedItems = {};
      
          for (let item of limitedItems) {
            // @ts-ignore
              if (limitedGroupedItems[item.groupName]) {
                // @ts-ignore
                  limitedGroupedItems[item.groupName].push(item);
              } else {
                // @ts-ignore
                  limitedGroupedItems[item.groupName] = [item];
              }
          }
      
          return limitedGroupedItems;
      }

        const limitedItems = getLimitedItems(groupedItems, numberOfItemsToShow);

        const isAscending = false; 

        const sortedGroupKeys = Object.keys(limitedItems).sort((a, b) => {
          const isAYear = /^\d{4}$/.test(a);
          const isBYear = /^\d{4}$/.test(b);
          
          let result: number;
          
          if (isAYear && isBYear) {
            result = Number(a) - Number(b); 
          } else if (isAYear) {
            result = -1;
          } else if (isBYear) {
            result = 1;
          } else {
            result = moment(a, 'MMMM YYYY').valueOf() - moment(b, 'MMMM YYYY').valueOf(); 
          }
          
          return isAscending ? result : -result;
        });
        

        return (
          <div>
          {sortedGroupKeys.map((formattedDateOfGroup) => (
            <div key={formattedDateOfGroup}>
              <div className="mb-3" style={{fontWeight: 'bold', fontSize: 14, color: colors.secondaryText}}>
                {formattedDateOfGroup}
              </div>
              <div>
                {/* @ts-ignore */}
                {limitedItems[formattedDateOfGroup].map((item, index) => (
                  <div key={index} className="timeline-item">
                    <div className="timeline-dot" />
                    {/* @ts-ignore */}
                    <div className={index !== limitedItems[formattedDateOfGroup].length - 1 ? "timeline-line" : ""}></div>
                
                    <div className="mb-2 ml-2" style={{ display: "flex", alignItems: "center" }}>
                      <div className="mr-3" style={{ display: "inline-block" }}>
                        <img
                          alt="membershipCard"
                          src={item.id?.images?.mainImage || item.id.restaurantId?.images?.mainImage}
                          style={{
                            width: 80,
                            height: 48,
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
        
                      <div>
                        <div style={{ fontSize: 12 }}>{title(item)}</div>
                        <div style={{ fontSize: 10, color: "#7F7F7F" }}>{moment.utc(item.actionDate).tz(formatTimezone).format("DD.MM.YYYY HH:mm")}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        
        
        );
      };

      return (
        <div>
          <GroupedItems/>
          {numberOfItemsToShow < timeLineItemsCount &&
            <div>
              <Button style={{padding: 0, fontSize: 14}} variant={"link"}
                      onClick={() => {
                        setNumberOfItemsToShow((numberOfItemsToShow: number) => 1000)
                  
                      }}>
                {t("memberProfileScreen.seeAllActivities")}
              </Button>
            </div>
          }

          
          </div>
             
      )
    }

    function formatDateAgeGender(): string {

      if (!member) {
        return ''
      }

      const birthday = member.birthDay ? new Date(member.birthDay) : ''
      const gender = member.gender && member.gender.length ? member.gender[0] : undefined
      const genderTranslated = () => {
        if (gender === 'male') {
          return t("memberProfileScreen.male")
        }

        if (gender === 'female') {
          return t("memberProfileScreen.female")
        }

        if (gender === 'otherGender') {
          return t("memberProfileScreen.otherGender")
        }

        if (gender === 'nonBinary') {
          return t("memberProfileScreen.nonBinary")
        }

        return gender
      }
      const date =  moment(birthday);
    
      // Format date
      const formattedDate = birthday ? `${date.format("D.M.YYYY")} |` : '';
    
      // Calculate age
      const age = birthday ? `${t("memberProfileScreen.age")} ${moment().diff(date, 'years')}` : '';

      
      

      // Combine formatted date, age, and gender
      const result = `${formattedDate} ${age} ${gender ? ` | ${genderTranslated()}` : ''}`

      return result;
    }

    const ChangeStatusNumberModal = () => {

      // useState statusNumber
      const [statusNumber, setStatusNumber] = useState<string | null>(null)

      const handleChangeStatusNumber = async (userVenueStatusId: string, externalIdentifier: string | null) => {
     
        await api?.updateMemberVenueStatusExternalIdentifier(userVenueStatusId, externalIdentifier, undefined, MEMBER_SCREEN_ERROR)
        member.updateExternalIdentifier(userVenueStatusId, externalIdentifier)
      
        setForceResolveMemberFlag(!forceResolveMemberFlag)
      }

      // rootStore!.dimWhile(handleChangeStatusNumber)

      return    <ModalBox getRef={(ref: any) => changeStatusNumberModalRef.current = ref}
      title={t("memberProfileScreen.modals.changeStatusNumber.title")}
      renderContent={(action, cancel, metadata) => {
        return (
          <>
            <Modal.Body>
            <input
                id="cardNumber"
                name="cardNumber"
                type="text"
                className="form-control"
                style={{
                  width: '50%',
                  margin: '0 auto'
                }}
                onChange={(e) => setStatusNumber(e.target.value)}
                defaultValue={metadata.externalIdentifier}
                // value={statusNumber || ''}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // value={formik.values.fullName}
                // className={`form-control ${
                //     formik.errors.fullName && formik.touched.fullName ? 'is-invalid' : ''
                // }`}
                />
           </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={cancel}>
                {t("memberProfileScreen.modals.removeStatusConfirm.cancelButton")}
              </Button>
              <Button variant="primary" onClick={() => {
                handleChangeStatusNumber(metadata.userVenueStatusId, statusNumber)
                cancel()
                }}>
                {t("memberProfileScreen.modals.changeStatusNumber.okButton")}
              </Button>
            </Modal.Footer>
          </>
        )
      }}/>
    }
    
    const email = member && member.venueMemberships && member.venueMemberships.length && member.venueMemberships[0].email ? member.venueMemberships[0].email : '';

    if (!member) {
      return null
    }

    return (
      <>
        <ModalBox getRef={(ref: any) => removeStatusConfirmModalRef.current = ref}
                  title={t("memberProfileScreen.modals.removeStatusConfirm.title")}
                  renderContent={(action, cancel, metadata) => {
                    return (
                      <>
                        <Modal.Body>{t("memberProfileScreen.modals.removeStatusConfirm.text", {statusTitle: metadata.statusTitle})}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberProfileScreen.modals.removeStatusConfirm.cancelButton")}
                          </Button>
                          <Button variant="primary" onClick={action}>
                            {t("memberProfileScreen.modals.removeStatusConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

<ModalBox getRef={(ref: any) => removeMembershipModalRef.current = ref}
                  title={t("memberProfileScreen.modals.removeMembership.title")}
                  renderContent={(action, cancel, metadata) => {
                    return (
                      <>
                        <Modal.Body>{t("memberProfileScreen.modals.removeMembership.text", {venueName: metadata.venueName})}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberProfileScreen.modals.removeMembership.cancelButton")}
                          </Button>
                          <Button variant="danger" onClick={action}>
                            {t("memberProfileScreen.modals.removeMembership.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

          <ChangeStatusNumberModal/>
        {/*
        <ModalBox getRef={(ref: any) => addStatusConfirmModalRef = ref.current}
                  title={t("memberProfileScreen.modals.addStatusConfirm.title")}
                  renderContent={(action, cancel, metadata) => {
                    return (
                      <>
                        <Modal.Body>{t("memberProfileScreen.modals.addStatusConfirm.text", {statusTitle: metadata.statusTitle})}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberProfileScreen.modals.addStatusConfirm.cancelButton")}
                          </Button>
                          <Button variant="primary" onClick={action}>
                            {t("memberProfileScreen.modals.addStatusConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/> */}
        <AlertMessage source={MEMBER_SCREEN_ERROR}/>

        <div className="container p-0">
          <div className="row">
            <div className="col-md-6">
              {/* First Column Content */}
              <Card>
                <Card.Header as="h5" style={{fontWeight: 'bold'}}>
                  {/* <FontAwesomeIcon className="mr-2" icon={["fal", "user"]} /> */}
                  {t("memberProfileScreen.memberProfileTitle")}
                </Card.Header>
                <Card.Body className="pt-2" style={{
                  position: 'relative'
                }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {member.profileImages?.small &&
                      <div style={{display: 'inline-block'}}>
                        <img 
                          alt="membershipCard"
                          src={member.profileImages?.small} 
                          style={{width: 80, height: 80, objectFit: 'cover', borderRadius: '50%'}}/>
                      </div>
                    }
                    

                    <div style={{display: 'inline-block', marginLeft: member.profileImages?.small ? 20 : 0}}>
                      <div style={{fontSize: 18, fontWeight: 'bold'}}>{member.name}</div>
                      <div style={{fontSize: 12, color: colors.secondaryText}}>{formatDateAgeGender()}</div>
                      {email &&
                        <div style={{fontSize: 10, color: colors.secondaryText}}>{email}</div>
                      }
                      
                    </div>
                  </div>
                   
                  {/* <StatisticsSummary/> */}
                
          
                  {userSessionStore!.currentOrganization!.isSingleVenue()
                            ? _renderMembershipsOrg(member)
                            : _renderMembershipsOrg(member)}

                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 pr-1">
              <Card>
                <Card.Header as="h5" style={{fontWeight: 'bold'}}>
                  {t("memberProfileScreen.cardsAndSubscriptions")}
                </Card.Header>
                <Card.Body className="pl-4 pt-3">
                  <CardsAndSubscriptions member={member}/>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <div className="container p-0 mt-4">
        <div className="col-md-12 p-0">
              <Card>
                <Card.Header as="h5" style={{fontWeight: 'bold'}}>
                  {t("memberProfileScreen.recentActivities")}
                </Card.Header>
                <Card.Body className="pl-4 pt-3">
                  <RecentActivities/>
                </Card.Body>
              </Card>
            </div>
        </div>

        <Modal show={showRemovalSuccessModal} centered onHide={handleMembershipRemovalComplete}>
          <Modal.Header>
            <Modal.Title className="text-center w-100">
              <div className="font-weight-bold">{t("memberProfileScreen.modals.removeMembershipSuccess.title")}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="mt-2" style={{fontSize: 18}}>
              {t("memberProfileScreen.modals.removeMembershipSuccess.text", { venueName: removedMembershipName })}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="primary" onClick={handleMembershipRemovalComplete}>
              {t("memberProfileScreen.modals.removeMembershipSuccess.closeButton")}
            </Button>
          </Modal.Footer>
        </Modal>

    
        
      </>
    )
  }));

export default withRouter(withTranslation()(MemberScreen));
