import { IMemberActivity, MEMBER_ACTIVITY_TYPE } from "../../../models/MemberStore";

export const getActivityText = (activity: IMemberActivity, t: any) => {
    const text = (() => {
      switch (activity.type) {
        case MEMBER_ACTIVITY_TYPE.BENEFIT_ACTIVATION:
          return `${t('memberProfileScreen.activities.activatedABenefit')} · ${activity.benefit?.localeTitle()}`;
        case MEMBER_ACTIVITY_TYPE.EVENT_PURCHASE:
          return `${t('memberProfileScreen.activities.purchasedATicket')} · ${activity.event?.title}`;
        case MEMBER_ACTIVITY_TYPE.VENUE_STATUS_PURCHASE:
          return `${t('memberProfileScreen.activities.purchasedASubscription')} · ${activity.subscription?.localeTitle}`;
        case MEMBER_ACTIVITY_TYPE.VENUE_PRODUCT_PURCHASE:
          return `${t('memberProfileScreen.activities.purchasedAProduct')} · ${activity.product?.title}`;
        case MEMBER_ACTIVITY_TYPE.STAMP:
          return `${t('memberProfileScreen.activities.collectedAStamp')} · ${activity.stampCard?.localeName()} · ${activity.venue?.name}`;
        case MEMBER_ACTIVITY_TYPE.PRODUCT_ACTIVATION:
          return `${t('memberProfileScreen.activities.activatedAProduct')} · ${activity.product?.title}`;
        default:
          return activity.type;
      }
    })();
  
    if (!text) {
      return ''
    }
  
    return text.charAt(0).toLowerCase() + text.slice(1);
  };

  interface ListLengthConfig {
    currentLength: number;
    otherLength: number;
    maxItems?: number;
  }
  
  export const getDisplayLength = ({ currentLength, otherLength, maxItems = 10 }: ListLengthConfig): number => {
    if (otherLength === 0) return Math.min(currentLength, maxItems);
    return Math.min(currentLength, otherLength, maxItems);
  };