import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../utils/analytics'

export function PostHogPageviewTracker() {
  const location = useLocation()
  const posthog = usePostHog()

  useEffect(() => {
    // Track pageview when location changes
    trackEvent('$pageview', {
      current_url: window.location.href,
      pathname: location.pathname
    })
  }, [location, posthog])

  return null
}
