import {applySnapshot, destroy, flow, getEnv, getSnapshot, Instance, types} from "mobx-state-tree"
import {UserSessionStore} from "./UserSessionStore"
import {BenefitStore} from "./BenefitStore"
import {BenefitReportStore} from "./BenefitReportStore"
import {ProductReportStore} from "./ProductReportStore"
import {MemberStore} from "./MemberStore"
import {EventStore} from "./EventStore"
import {ProductStore} from "./ProductStore"
import {EventReportStore} from "./EventReportStore"
import log from "loglevelnext"
import * as _ from "lodash";
import { MessageStore } from "./MessageStore"
import { InvitationsStore } from "./InvitationsStore"
import { LoyaltyCardStore } from "./LoyaltyCardStore"
import { RewardStore } from "./RewardStore"

export const DataStore = types.model("DataStore").props({
  benefitStore: types.optional(BenefitStore, {}),
  productStore: types.optional(ProductStore, {}),
  rewardStore: types.optional(RewardStore, {}),
  loyaltyCardStore: types.optional(LoyaltyCardStore, {}),
  productReportStore: types.optional(ProductReportStore, {}),
  benefitReportStore: types.optional(BenefitReportStore, {}),
  memberStore: types.optional(MemberStore, {}),
  messageStore: types.optional(MessageStore, {}),
  invitationsStore: types.optional(InvitationsStore, {}),
  eventStore: types.optional(EventStore, {}),
  eventReportStore: types.optional(EventReportStore, {})
})

export type IDataStore = Instance<typeof DataStore>

export const RootStore = types.model("RootStore")
  .props({
    dataStore: DataStore,
    preloadDataStore: types.frozen(),
    preloadDataStoreUpdates: types.optional(types.array(types.string), []),
    dataChecksum: types.optional(types.map(types.string), {}),
    userSessionStore: types.optional(UserSessionStore, {}),
    appStatus: types.optional(
      types.model({
        preloadTimeoutHandle: types.maybe(types.number),
        hasPreloadedData: types.boolean,
        dataLoadingDim: types.boolean,
        dataLoading: types.boolean,
        dataLoadingError: types.boolean,
        dataLoadingPercentage: types.optional(types.number, 0),
      }), {hasPreloadedData: false, dataLoading: false, dataLoadingError: false, dataLoadingDim: false},
    ),
  }).views(self => ({}))
  .actions(self => ({
    resetData() {
      log.debug("RootStore: resetData")
      window.clearTimeout(self.appStatus.preloadTimeoutHandle)
      self.dataStore.benefitReportStore.resetData()
      self.dataStore.productReportStore.resetData()
      self.dataStore.benefitStore.resetData()
      self.dataStore.productStore.resetData()
      self.dataStore.rewardStore.resetData()
      self.dataStore.loyaltyCardStore.resetData()
      self.dataStore.memberStore.resetData()
      self.dataStore.messageStore.resetData()
      self.dataStore.invitationsStore.resetData()
      self.dataStore.eventReportStore.resetData()
      self.dataStore.eventStore.resetData()
      self.dataChecksum.clear()
      self.appStatus.hasPreloadedData = false
      self.preloadDataStoreUpdates.clear()
    },
    setHasPreloadedData(boolean: boolean) {
      self.appStatus.hasPreloadedData = boolean
    },
    setDataLoadingDim(value: boolean) {
      self.appStatus.dataLoadingDim = value
    }
  })).actions(self => ({
    loadPreloadData() {
      log.debug("Loading new preload data")

      const preloadDataStore = DataStore.create(self.preloadDataStore)
      self.preloadDataStoreUpdates.forEach((store: string) => {
        log.debug("Preload update for store %s", store)
        // @ts-ignore
        applySnapshot(self.dataStore[store], getSnapshot(preloadDataStore[store]))
      })
      destroy(preloadDataStore)
      //applySnapshot(self.dataStore, self.preloadDataStore)
      self.setHasPreloadedData(false)
    },
    async dimWhile(callback: () => void) {
      self.setDataLoadingDim(true)
      await callback()
      self.setDataLoadingDim(false)
    },
    fetchData: flow(function* fetchData(reset: boolean = true, preload = false, disableLoadingScreen = false) {
      if (reset) self.resetData()

      if (self.appStatus.dataLoading) {
        console.log("RootStore: Already fetching data, skipping")
        return
      }
  

      window.clearTimeout(self.appStatus.preloadTimeoutHandle)
      self.setHasPreloadedData(false)
      //self.appStatus.dataLoadingPercentage = 0
      self.appStatus.dataLoading = !(preload || disableLoadingScreen)
      self.appStatus.dataLoadingDim = !preload && disableLoadingScreen

      const preloadDataStore: IDataStore = DataStore.create({
        eventStore: getSnapshot(self.dataStore.eventStore)
      }, {
        api: getEnv(self).api,
        userSessionStore: getEnv(self).userSessionStore
      })

      const dataStoreFetches: Array<any> = [
        {
          store: ["eventStore", "eventReportStore"],
          fetch: [preloadDataStore.eventStore.fetchData, preloadDataStore.eventReportStore.fetchData]
        },
        {store: "memberStore", fetch: preloadDataStore.memberStore.fetchData},
        {store: "benefitStore", fetch: preloadDataStore.benefitStore.fetchData},
        {store: "benefitReportStore", fetch: () => preloadDataStore.benefitReportStore.fetchData(undefined, undefined)},
        {store: "productReportStore", fetch: preloadDataStore.productReportStore.fetchData},
        {store: "productStore", fetch: preloadDataStore.productStore.fetchData},
        {store: "rewardStore", fetch: preloadDataStore.rewardStore.fetchData},
        {store: "loyaltyCardStore", fetch: preloadDataStore.loyaltyCardStore.fetchData},
        {store: "messageStore", fetch: preloadDataStore.messageStore.fetchData},
        {store: "invitationsStore", fetch: preloadDataStore.invitationsStore.fetchData},
      ]

      log.debug("RootStore: fetchData, reset: %s, preload: %s", reset, preload)
      const fetchDataPromises: any[] = []
      try {
        for (let index = 0; index < dataStoreFetches.length; index++) {
          const fetch = _.isArray(dataStoreFetches[index].fetch) ?
            async () => {
              const results: string[] = []
              for (const fetch of dataStoreFetches[index].fetch) {
                results.push(await fetch())
              }
              return results
            } :
            dataStoreFetches[index].fetch
          fetchDataPromises.push(fetch())
        }

        const fetchDataResults = yield Promise.all(fetchDataPromises)
        const dataChecksum = new Map<string, string>()

        fetchDataResults.forEach((checksum: string, index: number) => {
          if (_.isArray(dataStoreFetches[index].store)) {
            dataStoreFetches[index].store.forEach((store: string, index: number) => {
              log.debug("%s#%s data fetch checksum: %s", store, index, checksum[index])
              dataChecksum.set(store, checksum[index] || "")
            })
          } else {
            log.debug("%s data fetch checksum: %s", dataStoreFetches[index].store, checksum)
            dataChecksum.set(dataStoreFetches[index].store, checksum || "")
          }
        })

        const updateStores = Array.from(dataChecksum.keys()).filter((store) => {
          return dataChecksum.get(store) !== self.dataChecksum.get(store)
        })

        if (updateStores.length > 0) {
          log.debug("Updated data fetched: %s", updateStores.toString())
          self.dataChecksum.replace(dataChecksum)

          if (!preload) {
            updateStores.forEach((store: string, index: number) => {
              //self.appStatus.dataLoadingPercentage = Math.ceil((index + 1) / updateStores.length * 100)
              log.debug("Updating store %s", store)
              // @ts-ignore
              applySnapshot(self.dataStore[store], getSnapshot(preloadDataStore[store]))
            })
          } else {
            self.preloadDataStoreUpdates.replace(updateStores)
            self.preloadDataStore = getSnapshot(preloadDataStore)
            self.setHasPreloadedData(true)
          }
        } else {
          log.debug("No new data fetched")
        }
        destroy(preloadDataStore)

        return true
      } catch (e) {
        log.debug("RootStore: fetchData error: ", e)
        console.log('RootStore: fetchData error', e)
        self.appStatus.dataLoadingError = true
        return false
      } finally {
        self.appStatus.preloadTimeoutHandle = window.setTimeout(() => {
          log.debug("Triggering background fetch")
          // @ts-ignore
          self.fetchData(false, true)
        }, 3 * 60 * 1000)
        self.appStatus.dataLoading = false
        self.appStatus.dataLoadingDim = false
      }
    }),
  }))

export type IRootStore = Instance<typeof RootStore>
