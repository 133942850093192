import { useState } from "react"
import { FormControl, FormGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next"
import { IVenueReferralCode } from "../../models/MemberStore"
import { colors } from "../../theme/colors"

type ModalView = 'initial' | 'changeCode' | 'confirmation';

const ChangeInvitationCodeModal = ({
  showChangeInvitationCodeModal,
  setShowChangeInvitationCodeModal,
  onGenerateCode,
  referralCode,
  onRefetchVenueCards
}:{
  showChangeInvitationCodeModal: boolean
  setShowChangeInvitationCodeModal: (show: boolean) => void
  onGenerateCode?: (customCode?: string) => void
  referralCode?: IVenueReferralCode
  onRefetchVenueCards?: () => void
}) => {
  const [currentView, setCurrentView] = useState<ModalView>('initial');
  const [showCustomCodeInput, setShowCustomCodeInput] = useState(true);
  const [customCode, setCustomCode] = useState('');
  const [customCodeError, setCustomCodeError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingCustomCode, setPendingCustomCode] = useState<string | undefined>();
  const {t} = useTranslation();

  const handleCustomCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    setCustomCode(value);
    if (value.length < 6) {
      setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.customCodeError"));
    } else {
      setCustomCodeError('');
    }
  };
  
  const handleSetCustomCode = async () => {
    if (customCode.length >= 6) {
      setPendingCustomCode(customCode);
      setCurrentView('confirmation');
    }
  };

  const handleAutoCodeGeneration = () => {
    setPendingCustomCode(undefined);
    setCurrentView('confirmation');
  };

  const handleConfirmChange = async () => {
    setIsSubmitting(true);
    try {
      if (onGenerateCode) {
        const result = await onGenerateCode(pendingCustomCode);
        
        if (Boolean(result)) {
          handleClose();
        } else {
          setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.apiError"));
          if (onRefetchVenueCards) {
            await onRefetchVenueCards();
          }
        }
      }
    } catch (error) {
      setCustomCodeError(t("manageMembersCardScreen.modals.changeInvitationCode.apiError"));
    }
    setIsSubmitting(false);
    setCurrentView('changeCode');
  };

  const handleClose = () => {
    setShowChangeInvitationCodeModal(false);

    setTimeout(() => {
      setShowCustomCodeInput(true);
      setCustomCode('');
      setCustomCodeError('');
      setPendingCustomCode(undefined);
      setCurrentView('initial');

    }, 400);
  };

  const renderConfirmationContent = () => (
    <>
      <Modal.Header>
        <Modal.Title style={{ margin: '0 auto' }}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.confirmTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {t("manageMembersCardScreen.modals.changeInvitationCode.confirmDescription")}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="outline-primary" 
          onClick={() => setCurrentView('changeCode')}
          disabled={isSubmitting}
        >
          {t("manageMembersCardScreen.modals.changeInvitationCode.no")}
        </Button>
        <Button 
          variant="primary" 
          onClick={handleConfirmChange}
          disabled={isSubmitting}
        >
          {t("manageMembersCardScreen.modals.changeInvitationCode.yes")}
        </Button>
      </Modal.Footer>
    </>
  );

  const renderChangeCodeContent = () => (
    <>
      <Modal.Header>
        <Modal.Title style={{ margin: '0 auto' }}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3" style={{
          fontSize: 16
        }}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.currentCode")}
          <div className="font-weight-bold" style={{fontSize: 24}}>{referralCode?.code || '-'}</div>
        </div>

        {showCustomCodeInput ? (
          <>
            <div className="text-center mb-3" style={{fontSize: 16}}>
              {t("manageMembersCardScreen.modals.changeInvitationCode.newCode")}
            </div>
            <FormGroup controlId="customCode">
              <FormControl
                type="text"
                value={customCode}
                onChange={handleCustomCodeChange}
                placeholder={t("manageMembersCardScreen.modals.changeInvitationCode.customCodePlaceholder")}
                className="text-center mb-3"
              />
            </FormGroup>
            <div className="text-center mt-3">
              <Button
                variant="link"
                onClick={() => {
                  setShowCustomCodeInput(false);
                  setCustomCodeError('');
                  setCustomCode('');
                }}
              >
                {t("manageMembersCardScreen.modals.changeInvitationCode.autoCodeGeneration")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="text-center mt-3">
              <Button
                variant="link"
                onClick={() => {
                  setShowCustomCodeInput(true);
                  setCustomCodeError('');
                }}
              >
                {t("manageMembersCardScreen.modals.changeInvitationCode.customCode")}
              </Button>
            </div>
          </>
        )}

        {customCodeError && (
          <div style={{
            textAlign: 'center',
            color: colors.red,
            fontSize: '14px',
            marginTop: 10
          }}>
            {customCodeError}
          </div>
        )}

        <div className="text-center mt-4">
          <small className="text-secondary">
            {t("manageMembersCardScreen.modals.changeInvitationCode.description")}
          </small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.cancel")}
        </Button>
        {showCustomCodeInput ? (
          <Button 
            variant="primary" 
            onClick={handleSetCustomCode} 
            disabled={customCode.length < 6 || isSubmitting}
          >
            {t("manageMembersCardScreen.modals.changeInvitationCode.setNewCode")}
          </Button>
        ) : (
          <Button 
            variant="primary" 
            onClick={handleAutoCodeGeneration} 
            disabled={isSubmitting}
          >
            {t("manageMembersCardScreen.modals.changeInvitationCode.generateNewCode")}
          </Button>
        )}
      </Modal.Footer>
    </>
  );

  const renderInitialContent = () => (
    <>
      <Modal.Header>
        <Modal.Title style={{ margin: '0 auto' }}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.invitationCode")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3" style={{
          fontSize: 16
        }}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.currentCode")}
          <div className="font-weight-bold" style={{fontSize: 24}}>{referralCode?.code || '-'}</div>
        </div>
        <div className="text-center">
          <small className="text-secondary">
          {t("manageMembersCardScreen.modals.changeInvitationCode.description")}
          </small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t("back")}
        </Button>
        <Button variant="primary" onClick={() => setCurrentView('changeCode')}>
          {t("manageMembersCardScreen.modals.changeInvitationCode.changeCode")}
        </Button>
      </Modal.Footer>
    </>
  );

  return (
    <Modal show={showChangeInvitationCodeModal} centered onHide={handleClose}>
      {currentView === 'initial' && renderInitialContent()}
      {currentView === 'changeCode' && renderChangeCodeContent()}
      {currentView === 'confirmation' && renderConfirmationContent()}
    </Modal>
  );
}

export default ChangeInvitationCodeModal