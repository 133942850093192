export default {
  screens: {
    profile: "Profile screen",
    members: "Members screen",
    paidSubscriptions: "Sell subscriptions screen",
    addNewPaidSubscription: "Add new sellable subscription screen",
    editSubscription: "Edit sellable subscription screen",
    paidStatuses: "Sell statuses screen",
    addPaidStatus: "Add new sellable status screen",
    editPaidStatus: "Edit sellable status screen",
    freeStatuses: "Give statuses screen",
    addFreeStatus: "Add new free status screen",
    editFreeStatus: "Edit free status screen",
    events: "Events screen",
    eventsModify: "Events modify screen",
    benefits: "Benefits screen",
    benefitsModify: "Benefits modify screen",
    home: "Home screen",
    memberProfile: "Member profile screen",
    messages: "Messages screen",
    sendPushMessage: "Send push message screen",
    editEmailMessage: "Edit email message screen",
    editPushMessage: "Edit push message screen",
    changeOrganization: "Change organization screen",
    manageVenues: "Manage venues screen",
    manageVenueDetails: "Manage venue details screen",
    addNewVenue: "Add new venue screen",
    manageUsers: "Manage users screen",
    connectEmailOctopus: "Connect EmailOctopus screen"
  }
}
