import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { IInvitationStore } from '../../models/InvitationsStore'

interface CancelInvitationModalProps {
  showModal: boolean
  setShowModal: (show: boolean) => void
  invitation: any
  invitationsStore?: IInvitationStore
}

const CancelInvitationModal = inject("invitationsStore")(observer((props: CancelInvitationModalProps) => {
  const { t } = useTranslation()
  const { showModal, setShowModal, invitation, invitationsStore } = props
  const [isCanceling, setIsCanceling] = useState(false)

  const handleConfirm = async () => {
    if (!invitation) return
    
    setIsCanceling(true)
    try {
      await invitationsStore?.cancelInvitation(invitation.email)
      setShowModal(false)
    } finally {
      setIsCanceling(false)
    }
  }

  if (!invitation) return null

  return (
    <Modal show={showModal} centered onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <div className="font-weight-bold">{t("invitationsScreen.cancelInvitationPopUp.title")}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="mt-2" style={{fontSize: 18}}>
          {t("invitationsScreen.cancelInvitationPopUp.description", { userEmail: invitation.email })}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="outline-primary" onClick={() => setShowModal(false)}>
          {t("invitationsScreen.cancelInvitationPopUp.cancel")}
        </Button>
        <Button 
          variant="danger" 
          disabled={isCanceling}
          onClick={handleConfirm}
        >
          {t("invitationsScreen.cancelInvitationPopUp.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}))

export default CancelInvitationModal