import log from "loglevelnext"
import { flow, getEnv, Instance, types } from "mobx-state-tree"
import { IInvitation, Invitation } from "./Invitation"


export const InvitationsStore = types.model("BenefitStore")
  .props({
    invited: types.optional(types.late(() => types.array(Invitation)), []),
    users: types.optional(types.late(() => types.array(Invitation)), []),
  }).views(self => ({

  }))
  .views(self => ({
  }))
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      log.debug("MemberStore: fetchData")
      const response = yield getEnv(self).api.fetchInvitations(undefined, undefined, requestSource)

      if (response.modified) {
        self.invited.replace(response.payload.data.invited)
        self.users.replace(response.payload.data.users)
      }
      return response.etag
    }),
    addInvitation(invitation: IInvitation) {
      self.invited.push(invitation)
    },
    updateInvitation(invitation: any) {
      const messageToUpdateIndex = self.invited.findIndex(s => s.id === invitation.id)
      self.invited[messageToUpdateIndex] = invitation
    },
    removeUser: flow(function* removeUser(memberId: string) {
      log.debug(`InvitationStore: removeUser with memberId: ${memberId}`);
      
      const response = yield getEnv(self).api.removeMember(memberId);

      
      if (response) {
        const indexToRemove = self.users.findIndex(user => user.id === memberId);
        if (indexToRemove > -1) {
          self.users.splice(indexToRemove, 1);
        }
      }
      
      return response;
    }),
    resetData() {
      log.debug("InvitationStore: resetData")
      self.invited.clear()
    },
    cancelInvitation: flow(function* cancelInvitation(email: string) {
      log.debug(`InvitationStore: cancelInvitation for email: ${email}`);
      
      const response = yield getEnv(self).api.cancelInvitation(email);
      
      if (response) {
        const indexToRemove = self.invited.findIndex(invitation => invitation.email === email);
        if (indexToRemove > -1) {
          self.invited.splice(indexToRemove, 1);
        }
      }
      
      return response;
    }),
  }))

export type IInvitationStore = Instance<typeof InvitationsStore>
