import { useEffect, useState } from "react";
import { IMember } from "../../models/Member";

interface MemberWithTimelineState {
    loading: boolean;
    member: IMember | null;
    timeline: any | null;
  }
  
  export const useMemberWithTimeline = (memberStore: any, memberId: string) => {
    const [state, setState] = useState<MemberWithTimelineState>({
      loading: true,
      member: null,
      timeline: null
    });
  
    useEffect(() => {
      const fetchData = async () => {
        if (!memberId || !memberStore) {
          setState({ loading: false, member: null, timeline: null });
          return;
        }
  
        const result = await memberStore.fetchMemberWithTimeline(memberId);
        setState(result);
      };
  
      fetchData();
    }, [memberStore, memberId]);
  
    return state;
  };