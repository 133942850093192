import { TFunction } from 'i18next';
import React, { CSSProperties } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ILoyaltyCard } from '../../models/LoyaltyCard';
import { colors } from '../../theme/colors';
import { truncateText } from '../../utils';

interface StampCardData {
    title?: string;
    venueNames?: string;
    stampCollection?: string;
    stamps?: string;
    stampExpirationMonths?: string;
    rewardTitle?: string;
    rewardDescription?: string;
    rewardExpirationMonths?: string;
    membersWithStamps?: number;
    stampsCollected?: number;
    rewardsCollected?: number;
    rewardsRedeemed?: number;
  }
  
  interface StampCardProps {
    stampData: StampCardData;
    t: TFunction;
  }
  

  const columnTitleStyle: CSSProperties = {
    textAlign: 'center',
    color: colors.white,
    fontSize: 14,
    marginBottom: 10
  }

  const columnValueStyle: CSSProperties = {
    textAlign: 'center',
    color: colors.white,
    fontSize: 22,
    fontWeight: 'bold'
  }

  const detailStyle: CSSProperties = {
    textAlign: 'left',
    color: colors.secondaryText,
    fontSize: 16,
    marginBottom: 16
  }


  const StampCard: React.FC<StampCardProps> = ({ stampData, t }) => {
    return (
      <Card className="mt-4 smallCardPadding">
        <Card.Header style={{
            background: colors.boxFill,
            border: `1px solid #353535`,
            borderBottom: 'none',
        }} className="d-flex justify-content-between align-items-center">
          <div style={{
            fontSize: 20,
            color: colors.white
          }}>{stampData.title}</div>
          {/* <Button
          style={{
            background: 'transparent',
            color: colors.secondaryText,
            fontWeight: 'bold',
            borderColor: 'transparent'
          }}
          onClick={() => console.log('')}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "edit"]}/>{"Modify"}
          </Button> */}
        </Card.Header>
        <Card.Body className="pl-2 pr-2">
          <Container fluid>
            <Row>
              <Col xs={12} md={6}>
              {stampData.rewardDescription &&
                <div  style={detailStyle}>{truncateText(stampData.rewardDescription, 140)}</div>
                }
                {stampData?.venueNames &&
                     <div  style={detailStyle}>{stampData?.venueNames}</div>
                }
                {stampData.stampCollection &&
                  <div style={detailStyle}>{stampData.stampCollection}</div>
                }
              
                <div >
                  <div style={{
                    display: 'inline-block',
                    width: '50%'
                  }}>
                      {stampData.stamps &&
                        <div  style={detailStyle}>{`${stampData.stamps}`}</div>
                      }
                        {stampData.rewardTitle &&

                        <div  style={detailStyle}>{t('rewardsScreen.reward')}: {stampData.rewardTitle}</div>
                      }
                  </div>

                  <div style={{
                       display: 'inline-block',
                       width: '50%'
                  }}>
                      {stampData.stampExpirationMonths &&

                        <div  style={{...detailStyle, fontSize: 14}}>{stampData.stampExpirationMonths}</div>
                      }

                      {stampData.rewardExpirationMonths &&

                        <div  style={{...detailStyle, fontSize: 14}}>{stampData.rewardExpirationMonths}</div>
                      }
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={3} md={3}>
                    <div  style={columnTitleStyle}>{t('rewardsScreen.membersWithStamps')}</div>
                    <div style={columnValueStyle}>{stampData.membersWithStamps}</div>
                  </Col>
                  <Col xs={3} md={3} >
                    <div  style={columnTitleStyle}>{t('rewardsScreen.stampsCollected')}</div>
                    <div style={columnValueStyle}>{stampData.stampsCollected}</div>
                  </Col>
                  <Col xs={3} md={3} >
                    <div  style={columnTitleStyle}>{t('rewardsScreen.rewardsCollected')}</div>
                    <div style={columnValueStyle}>{stampData.rewardsCollected}</div>
                  </Col>
                  <Col xs={3} md={3} >
                    <div  style={columnTitleStyle}>{t('rewardsScreen.rewardsRedeemed')}</div>
                    <div style={columnValueStyle}>{stampData.rewardsRedeemed}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  };


const StampCardsList = ({stamps, isSingleVenue, t}: {stamps?: ILoyaltyCard[], isSingleVenue?: boolean, t: TFunction}) => {

  const getVenuesNamesThatHaveCard = (cardId: string) => {
    const card = stamps?.find(c => c.id === cardId);
  
    if (!card) return "";
  
    const venueNames = card.restaurantIds
      .map(restaurant => restaurant.name)
      .join(", ");
  
    return venueNames ? `${t('venues')}: ${venueNames}` : "";
  };

  const mappedCards: StampCardData[] = stamps?.map(card => {

    
    const stampsForReward = card?.stampsCountForAReward() || undefined;

    return {
    title: card.localeName() || "",
    ...(!isSingleVenue && {venueNames:  getVenuesNamesThatHaveCard(card.id)}) , 
    stampCollection:`${t('stampCardModifyScreen.stampCollection')}`, 
    stamps: `${t('stampCardModifyScreen.stampsForAReward', {
      stampsAmount: String(stampsForReward)
    })}`,
    stampExpirationMonths: `${t('stampCardModifyScreen.stampExpirationMonths', {
      months: String(24)
    })}`, 
    rewardTitle: card.localeRewardTitle(),
    rewardDescription: card.localeRewardDescription(),
    rewardExpirationMonths:  `${t('stampCardModifyScreen.rewardExpiration', {
      months: String(12)
    })}`, 
    membersWithStamps: card.total_unique_users || 0,
    stampsCollected: card.total_stamps || 0,
    rewardsCollected: card.total_rewards,
    rewardsRedeemed: card.rewards_redeemed || 0,
  }}) || [];

  return (
    <div>
      {mappedCards.map((card, index) => (
        <StampCard key={index} stampData={card} t={t} />
      ))}
    </div>
  );
};

export default StampCardsList;
