export interface CategoryType {
  [language: string]: string
}

// @FIXME: Add translations for categories to u18n files
export const categories = {
  "Activity": {en: 'Activity', fi: 'Aktiviteetti', es: 'Actividad'},
  "African": {en: 'African', fi: 'Afrikkalainen', es: 'Africano'},
  "American": {en: 'American', fi: 'Amerikkalainen', es: 'Americano'},
  "Asian": {en: 'Asian', fi: 'Aasialainen', es: 'Asiático'},
  "Bakery": {en: 'Bakery', fi: 'Leipomo', es: 'Panadería'},
  "Bar": {en: 'Bar', fi: 'Baari', es: 'Bar'},
  // "Barber shop": {en: 'Barber shop', fi: 'Parturi', es: 'Barbería'},
  "BBQ": {en: 'BBQ', fi: 'BBQ', es: 'Barbacoa'},
  // "Beauty salon": {en: 'Beauty salon', fi: 'Kauneushoito', es: 'Salón de belleza'},
  "Beer": {en: 'Beer', fi: 'Olut', es: 'Cerveza'},
  "Bistro": {en: 'Bistro', fi: 'Bistro', es: 'Bistró'},
  "Breakfast": {en: 'Breakfast', fi: 'Aamiainen', es: 'Desayuno'},
  "Brewery": {en: 'Brewery', fi: 'Panimo', es: 'Cervecería'},
  "British": {en: 'British', fi: 'Brittiläinen', es: 'Británico'},
  "Burger": {en: 'Burger', fi: 'Burgeri', es: 'Hamburguesa'},
  "Cafe": {en: 'Cafe', fi: 'Kahvila', es: 'Café'},
  "Chinese": {en: 'Chinese', fi: 'Kiinalainen', es: 'Chino'},
  "Cocktail": {en: 'Cocktail', fi: 'Cocktail', es: 'Cóctel'},
  "Cuban": {en: 'Cuban', fi: 'Kuubalainen', es: 'Cubano'},
  "Deli": {en: 'Deli', fi: 'Deli', es: 'Delicatessen'},
  "Dessert": {en: 'Dessert', fi: 'Jälkiruoka', es: 'Postre'},
  "Dinner": {en: 'Dinner', fi: 'Illallinen', es: 'Cena'},
  "European": {en: 'European', fi: 'Eurooppalainen', es: 'Europeo'},
  "Fastfood": {en: 'Fastfood', fi: 'Pikaruoka', es: 'Comida rápida'},
  "Fine dining": {en: 'Fine dining', fi: 'Fine dining', es: 'Alta cocina'},
  "Finnish": {en: 'Finnish', fi: 'Suomalainen', es: 'Finlandés'},
  "French": {en: 'French', fi: 'Ranskalainen', es: 'Francés'},
  "Gastro": {en: 'Gastro', fi: 'Gastro', es: 'Gastro'},
  "Georgian": {en: 'Georgian', fi: 'Georgialainen', es: 'Georgiano'},
  "German": {en: 'German', fi: 'Saksalainen', es: 'Alemán'},
  "Gin": {en: 'Gin', fi: 'Gin', es: 'Gin'},
  "Greek": {en: 'Greek', fi: 'Kreikkalainen', es: 'Griego'},
  "Grill": {en: 'Grill', fi: 'Grilli', es: 'Parrilla'},
  "Gym": {en: 'Gym', fi: 'Kuntosali', es: 'Gimnasio'},
  // "Hair salon": {en: 'Hair salon', fi: 'Kampaamo', es: 'Peluquería'},
  "Healthy": {en: 'Healthy', fi: 'Terveellinen', es: 'Saludable'},
  "Indian": {en: 'Indian', fi: 'Intialainen', es: 'Indio'},
  "Irish": {en: 'Irish', fi: 'Irlantilainen', es: 'Irlandés'},
  "Italian": {en: 'Italian', fi: 'Italialainen', es: 'Italiano'},
  "Japanese": {en: 'Japanese', fi: 'Japanilainen', es: 'Japonés'},
  "Juice Bar": {en: 'Juice Bar', fi: 'Mehubaari', es: 'Bar de jugos'},
  "Karaoke": {en: 'Karaoke', fi: 'Karaoke', es: 'Karaoke'},
  "Kebab": {en: 'Kebab', fi: 'Kebab', es: 'Kebab'},
  "Korean": {en: 'Korean', fi: 'Korealainen', es: 'Coreano'},
  "Lebanese": {en: 'Lebanese', fi: 'Libanonilainen', es: 'Libanés'},
  "Leisure": {en: 'Leisure', fi: 'Vapaa-aika', es: 'Ocio'},
  "Live music": {en: 'Live music', fi: 'Live-musiikki', es: 'Música en vivo'},
  "Lunch": {en: 'Lunch', fi: 'Lounas', es: 'Almuerzo'},
  // "Massage": {en: 'Massage', fi: 'Hieronta', es: 'Masaje'},
  "Mediterranean": {en: 'Mediterranean', fi: 'Välimerellinen', es: 'Mediterráneo'},
  "Mexican": {en: 'Mexican', fi: 'Meksikolainen', es: 'Mexicano'},
  "Middle-East": {en: 'Middle-East', fi: 'Lähi-itä', es: 'Medio Oriente'},
  // "Nail salon": {en: 'Nail salon', fi: 'Kynsihoito', es: 'Salón de uñas'},
  "Nightclub": {en: 'Nightclub', fi: 'Yökerho', es: 'Discoteca'},
  "Pizza": {en: 'Pizza', fi: 'Pizza', es: 'Pizza'},
  "Pub": {en: 'Pub', fi: 'Pub', es: 'Pub'},
  "Raw food": {en: 'Raw food', fi: 'Raakaruoka', es: 'Comida cruda'},
  "Restaurant": {en: 'Restaurant', fi: 'Ravintola', es: 'Restaurante'},
  "Russian": {en: 'Russian', fi: 'Venäläinen', es: 'Ruso'},
  "Salad": {en: 'Salad', fi: 'Salaatti', es: 'Ensalada'},
  "Scandinavian": {en: 'Scandinavian', fi: 'Pohjoismainen', es: 'Escandinavo'},
  "Seafood": {en: 'Seafood', fi: 'Merenelävät', es: 'Mariscos'},
  "Soups": {en: 'Soups', fi: 'Keitot', es: 'Sopas'},
  "Spa": {en: 'Spa', fi: 'Kylpylä', es: 'Spa'},
  "Spanish": {en: 'Spanish', fi: 'Espanjainen', es: 'Español'},
  "Sportbar": {en: 'Sportbar', fi: 'Sporttibaari', es: 'Bar deportivo'},
  "Steakhouse": {en: 'Steakhouse', fi: 'Pihviravintola', es: 'Asador'},
  "Street Food": {en: 'Street Food', fi: 'Katuruoka', es: 'Comida callejera'},
  "Sushi": {en: 'Sushi', fi: 'Sushi', es: 'Sushi'},
  "Swedish": {en: 'Swedish', fi: 'Ruotsalainen', es: 'Sueco'},
  "Terrace": {en: 'Terrace', fi: 'Terassi', es: 'Terraza'},
  "Tequila": {en: 'Tequila', fi: 'Tequila', es: 'Tequila'},
  "Thai": {en: 'Thai', fi: 'Thaimaalainen', es: 'Tailandés'},
  "Turkish": {en: 'Turkish', fi: 'Turkkilainen', es: 'Turco'},
  "Vegan": {en: 'Vegan', fi: 'Vegan', es: 'Vegano'},
  "Vegetarian": {en: 'Vegetarian', fi: 'Kasvisruoka', es: 'Vegetariano'},
  "Wellbeing": {en: 'Wellbeing', fi: 'Hyvinvointi', es: 'Bienestar'},
  "Wine": {en: 'Wine', fi: 'Viini', es: 'Vino'},
  "Wings": {en: 'Wings', fi: 'Wings', es: 'Alitas'},
  "Events": {en: 'Events', fi: 'Tapahtumat', es: 'Eventos'},
}
