import posthog from 'posthog-js'
import { trackOrganization } from '../tracking/trackingHelper'

const ORIGIN = 'Partner Hub'

const addOrigin = (properties?: Record<string, any>) => ({
  origin: ORIGIN,
  ...properties
})

export const trackEvent = (eventName: string, properties?: Record<string, any>) => {
  properties = addOrigin(properties)

  if (process.env.NODE_ENV === 'production') {
    posthog?.capture(eventName, properties)
  } else {
    console.log('PostHog Event (DEV):', eventName, properties)
  }
}

export const identifyUser = (userId?: string, properties?: Record<string, any>) => {
  properties = addOrigin(properties)

  if (process.env.NODE_ENV === 'production') {
    posthog?.identify(userId, properties)
  } else {
    console.log('PostHog Identify (DEV):', userId, properties)
  }
}

export const setGroup = (groupType: string, groupKey: string, properties?: Record<string, any>) => {
  properties = addOrigin(properties)

  trackOrganization(groupKey, properties)

  if (process.env.NODE_ENV === 'production') {
    posthog?.group(groupType, groupKey, properties)
  } else {
    console.log('PostHog Group (DEV):', groupType, groupKey, properties)
  }
}

export const reset = () => {
  posthog?.reset()
}
