
import { observer } from "mobx-react";
import { CSSProperties, useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useStores } from "../../hooks/UseStores";
import { MemberLocation } from "../../Locations";
import { colors } from "../../theme/colors";
import { timeAgo } from "../../utils/dateUtils";
import TruncateText from "../../components/TruncateText/TruncateText";
import blankProfileImage from "../HomeScreen/NewMembersList/blank-profile.png";
import { IMemberActivity } from "../../models/MemberStore";
import { getActivityText } from "../HomeScreen/RecentActivitiesList/utils";

  const listItemStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
    minHeight: "48px",
    height: "48px",
    maxHeight: "48px",
    padding: "4px 0"
  };
  
  const imageStyle: CSSProperties = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: "10px",
  };
  
  const infoStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
  };

const RecentMembersActivitiesScreen = observer(() => {
  const { t } = useTranslation();
  const { userSessionStore, memberStore } = useStores();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true);
      try {
        const response = await memberStore.fetchRecentActivities({
          page: 1,
          limit: 101
        });
        setHasMore(response?.length && response.length > 100);
        setCurrentPage(1);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  const handleLoadMore = async () => {
    setLoading(true);
    try {
      const nextPage = currentPage + 1;
      const response = await memberStore.fetchRecentActivities({
        page: nextPage,
        limit: 101
      });
      setHasMore(response.length > 100);
      setCurrentPage(nextPage);
    } finally {
      setLoading(false);
    }
  };

  const handleMemberClick = (memberId: string) => {
    history.push(MemberLocation.toUrl({
      ...userSessionStore!.selectedBranch!.urlFields(),
      memberId
    }));
  };

  return (
    <div className="container">
      <Card>
        <Card.Header as="h5" style={{
          backgroundColor: "#252525",
          color: colors.white,
          borderLeft: `1px solid ${colors.stroke}`,
          borderTop: `1px solid ${colors.stroke}`,
          borderRight: `1px solid ${colors.stroke}`,
        }}>
          {t("homeScreen.recentActivities")}
        </Card.Header>
        <Card.Body>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {memberStore.recentActivities.map((activity: IMemberActivity, index: number) => (
              <li 
                key={index} 
                style={listItemStyle}
                onClick={() => {
                  if (activity.member?.id) {
                    handleMemberClick(activity.member?.id)
                  }
                }}
              >
                <img
                  src={activity.member?.profileImages?.small || blankProfileImage}
                  alt={activity.member?.name}
                  style={imageStyle}
                />
                <div style={infoStyle}>
                  <div>
                    <TruncateText numberOfLines={1} text={`${activity.member?.name} ${getActivityText(activity, t)}`}/>
                  </div>
                  <small style={{ color: '#aaaaaa' }}>
                    {timeAgo(activity.actionDate)}
                  </small>
                </div>
              </li>
            ))}
          </ul>
          
          {hasMore && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                variant="secondary"
                onClick={handleLoadMore}
                disabled={loading}
              >
                {loading ?  <Spinner size="sm" animation="border" role="status" /> : t("loadMore")}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
});

export default RecentMembersActivitiesScreen;