import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { observer } from "mobx-react"
import moment from "moment"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { useTranslation } from "react-i18next"
import { IVenueReferralCode, IVenueStatus, MEMBER_STATUS_PAYMENT_VALIDITY_TYPE, VENUE_STATUS_THEME, VENUE_STATUS_TYPE } from "../models/MemberStore"
import { colors } from "../theme/colors"
import { centsToFullCurrencyUnits } from "../utils"
import ChangeInvitationCodeModal from "./Modals/ChangeInvitationCodeModal"

export interface MemebershipsSummaryCardProps {
  onEdit: () => void
  memberStatus: IVenueStatus
  referralCode?: IVenueReferralCode
  onGenerateCode?: (customCode?: string) => void
  onRefetchVenueCards?: () => void
}



export const MemebershipsSummaryCard = observer((props: MemebershipsSummaryCardProps) => {
  const [showChangeInvitationCodeModal, setShowChangeInvitationCodeModal] = useState(false);
  const {memberStatus, onEdit} = props

  const {t} = useTranslation()






  const statusThemeColor = () => {
    if (memberStatus.style?.theme === VENUE_STATUS_THEME.GOLD) {
      return t("manageMembersCardScreen.gold")
    } else if (memberStatus.style?.theme === VENUE_STATUS_THEME.BLACK) {
      return t("manageMembersCardScreen.black")
    } else {
      return ''
    }
  }


  const getValidityInMonths = (days: number | undefined): string => {
    if (!days) {
      return '';
    }
    
    switch(days) {
      case 30:
        return `1 ${t("manageMembersCardScreen.month")}`
      case 90:
        return `3 ${t("manageMembersCardScreen.months")}`
      case 180:
          return `6 ${t("manageMembersCardScreen.months")}`
      case 365:
        return `12 ${t("manageMembersCardScreen.months")}`
      case 730:
        return `24 ${t("manageMembersCardScreen.months")}`
      default:
        return ''
    }
  }

  const statusPrice = memberStatus.type === VENUE_STATUS_TYPE.SOLD ? `${centsToFullCurrencyUnits(memberStatus.payments?.price?.amount)}€` : t("manageMembersCardScreen.free")
  const getDateRange = (date1: string | undefined, date2: string | undefined): string => {
    if (!date1 || !date2) {
      return '';
    }
    return `${moment(new Date(date1)).format('YYYY-MM-DD')} - ${moment(new Date(date2)).format('YYYY-MM-DD')}`
  };

  const validity = memberStatus.payments.type === MEMBER_STATUS_PAYMENT_VALIDITY_TYPE.FIXED 
    ? getDateRange(memberStatus.payments.validFrom, memberStatus.payments.validThrough) 
    : getValidityInMonths(memberStatus.payments.duration)
  
  return (
    <Card className="mb-3 bg-white">
      <ChangeInvitationCodeModal 
        showChangeInvitationCodeModal={showChangeInvitationCodeModal}
        setShowChangeInvitationCodeModal={setShowChangeInvitationCodeModal}
        onGenerateCode={props.onGenerateCode}
        onRefetchVenueCards={props.onRefetchVenueCards}
        referralCode={props.referralCode}
      />
        <Card.Body className="p-2 m-0">
          <Container>
            <Row>
              <Col className="col-8 py-2 d-flex align-items-center justify-content-start">
                <div>
                  <h5
                    className="pl-1 m-0 font-heading font-weight-bold">{memberStatus?.localeTitle}</h5>
                  <p className="pl-1 m-0 mt-1">{memberStatus?.localeShortDescription}</p>
                  {/* TODO: Use a link once we know what that link is */}
                  {/* {membership.description === t("manageMembersCardScreen.availableForPurchase") &&
                    <a target="_blank" className="font-size-small d-block mt-1 mb-1" rel="noopener noreferrer" href="">
                      <FontAwesomeIcon className={"mr-2"} icon={["fal", "link"]}/>{t("manageMembersCardScreen.linkToCard")}
                    </a>
                  } */}

                  <p className="pl-1 m-0 mt-1 text-primary d-inline-block font-size-small">{t("manageMembersCardScreen.price")}: {statusPrice}</p>
                  {/* {statusThemeColor() &&
                    <p className="ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.color")}: {statusThemeColor()}</p>
                  } */}
                    {memberStatus.type === VENUE_STATUS_TYPE.GIVEN &&
                            <p className="ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.invitationCode")}: {props.referralCode?.code || '-'}  <FontAwesomeIcon onClick={() => setShowChangeInvitationCodeModal(true)} className="m-0 ml-2" style={{
                              color: colors.primaryText,
                              cursor: 'pointer'
                            }}
                              icon={["fal", "undo"]}/></p>
                      }
           
                  {validity &&
                    <p className="text-green ml-5 m-0 mt-1 d-inline-block font-size-small">{t("manageMembersCardScreen.valid")}: {validity}</p>
                  }
                </div>
              </Col>
              <Col className="col-2 d-flex align-items-center justify-content-end">
                {memberStatus?.images?.mainImage &&
                  <img 
                    alt="membershipCard"
                    src={memberStatus?.images?.mainImage} 
                    style={{width: 100, height: 60, objectFit: 'cover', borderRadius: '5px'}}/>
                }
              </Col>

              {/* <Col
                className="col-2 py-3 d-flex align-items-center justify-content-center bg-white rounded">
                <div className="text-center">
                </div>
              </Col> */}
              
              <Col className="col-2 py-2 d-flex align-items-center justify-content-end">
                <Button
                  variant={"transparent"}
                  onClick={onEdit}
                >
                  <FontAwesomeIcon className="m-0 mr-2" icon={["fal", "pen-to-square"]}/>
                  {t("manageMembersCardScreen.edit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
    </Card>
  )
})
